import { TextFilterArtifactsMatchMode } from '@contember/react-dataview'
import { InviteErrorCodes } from './tenant/hooks/useInvite'
import { UploaderErrorType } from '@contember/react-uploader'
import { ChangeMyPasswordFormErrorCode, CreateApiKeyFormErrorCode } from '@contember/interface'
import { InviteFormErrorCode, LoginFormErrorCode, OtpPrepareFormErrorCode, OtpConfirmFormErrorCode, PasswordResetFormErrorCode, PasswordResetRequestFormErrorCode, UpdateProjectMemberFormErrorCode, PasswordlessSignInInitFormErrorCode, PasswordlessSignInFormErrorCode } from '@contember/react-identity'
import { ActivatePasswordlessOtpErrorCode } from '@contember/graphql-client-tenant'

export const dict = {
	deleteEntityDialog: {
		title: 'Jste si naprosto jistí?',
		description: 'Tuto akci nelze vrátit zpět.',
		confirmButton: 'Smazat',
		cancelButton: 'Zrušit',
	},
	identityLoader: {
		fail: 'Nepodařilo se načíst identitu',
	},
	persist: {
		persistButton: 'Uložit data',
		invalidInputError: 'Nepodařilo se uložit data',
		invalidResponseError: 'Neplatná odpověď',
		invalidResponseErrorDetails: 'Server vrátil neplatnou odpověď. Zkuste to prosím později.',
		success: 'Úspěšně uloženo',
		afterPersistError: 'Po uložení dat došlo k chybě. Prosím obnovte stránku.',
	},
	toast: {
		showDetails: 'Zobrazit detaily',
	},
	input: {
		noValue: 'Žádná hodnota',
	},
	datagrid: {
		na: 'N/A',
		dateStart: 'Od',
		today: 'Dnes',
		dateEnd: 'Do',
		numberFrom: 'Od',
		numberTo: 'Do',
		textReset: 'Resetovat filtr',
		textPlaceholder: 'Hledat',
		textMatchMode: {
			'matches': 'obsahuje',
			'matchesExactly': 'rovná se',
			'startsWith': 'začíná na',
			'endsWith': 'končí na',
			'doesNotMatch': 'neobsahuje',
		} satisfies Record<TextFilterArtifactsMatchMode, string>,
		visibleFields: 'Pole',
		columnAsc: 'vzestupně',
		columnDesc: 'sestupně',
		columnHide: 'Skrýt',
		empty: 'Žádné výsledky.',
		layout: 'Rozložení',
		showGrid: 'Mřížka',
		showTable: 'Tabulka',
		paginationFirstPage: 'První stránka',
		paginationPreviousPage: 'Předchozí stránka',
		paginationNextPage: 'Další stránka',
		paginationLastPage: 'Poslední stránka',
		paginationRowsPerPage: 'Řádků na stránku',

		pageInfo: 'Stránka ${page} z ${pagesCount}',
		pageInfoShort: 'Stránka ${page}',
		pageRowsCount: 'Celkem ${totalCount} řádků',

		filter: 'Filtr',
		filters: 'Filtry',
		exclude: 'Vyloučit',
		export: 'Exportovat',

	},
	select: {
		confirmNew: 'Potvrdit',
		cancelNew: 'Zrušit',
		search: 'Hledat',
		paginationPreviousPage: 'Předchozí stránka',
		paginationNextPage: 'Další stránka',
		placeholder: 'Vybrat',
	},
	backButton: {
		back: 'Zpět',
	},

	errors: {
		required: 'Toto pole je povinné',
		unique: 'Tato hodnota je již obsazena',
		unknown: 'Došlo k neznámé chybě',
	},

	boolean: {
		true: 'Ano',
		false: 'Ne',
	},
	logout: 'Odhlásit se',
	inviteErrors: {
		ALREADY_MEMBER: 'Tento uživatel je již členem projektu',
		INVALID_EMAIL_FORMAT: 'Neplatný formát e-mailu',
		INVALID_MEMBERSHIP: 'Neplatné členství',
		PROJECT_NOT_FOUND: 'Projekt nenalezen',
		ROLE_NOT_FOUND: 'Role nenalezena',
		VARIABLE_EMPTY: 'Proměnná je prázdná',
		VARIABLE_NOT_FOUND: 'Proměnná nenalezena',
		fallback: 'Nepodařilo se pozvat uživatele',
	} satisfies Record<InviteErrorCodes | 'fallback', string>,
	uploader: {
		uploadErrors: {
			httpError: 'HTTP chyba',
			aborted: 'Nahrávání přerušeno',
			networkError: 'Síťová chyba',
			timeout: 'Časový limit nahrávání',
			fileRejected: 'Soubor odmítnut',
		} satisfies Record<UploaderErrorType, string>,
		uploaderUnknownError: 'Neznámá chyba',

		browseFiles: 'Procházet',
		dropFiles: 'Přetáhněte soubory sem',
		or: 'nebo',
	},
	repeater: {
		empty: 'Žádné položky.',
		addItem: 'Přidat položku',
	},
	outdatedApplication: {
		title: 'Je k dispozici aktualizovaná verze',
		description: 'Pro přístup k nejnovějším funkcím a vylepšením prosím obnovte svůj prohlížeč.',
		warning: 'Všechna neuložená data budou ztracena. Prosím uložte svou práci před obnovením.',
		snooze: 'Odložit',
		refreshNow: 'Obnovit nyní',
	},
	tenant: {
		memberList: {
			failedToLoadData: 'Nepodařilo se načíst data',
			noResults: 'Žádné výsledky',
			previous: 'Předchozí',
			next: 'Další',
			roles: 'Role',
			action: 'Akce',
		},
		apiKeyList: {
			deleted: 'API klíč smazán',
			deleteFailed: 'Nepodařilo se smazat API klíč',
			unnamed: 'Nepojmenovaný API klíč',
			noResults: 'Žádné API klíče',
			description: 'Popis',
			role: 'Role',
			action: 'Akce',
			deleteConfirmation: 'Opravdu chcete smazat tento API klíč?',
			deleteCancelAction: 'Zrušit',
			deleteConfirmAction: 'Smazat',
		},
		createApiKey: {
			description: 'Popis',
			roles: 'Role',
			submit: 'Vytvořit API klíč',
			errorMessages: {
				'FIELD_REQUIRED': 'Toto pole je povinné',
				'UNKNOWN_ERROR': 'Něco se pokazilo. Zkuste to prosím později',
				'INVALID_MEMBERSHIP': 'Neplatné členství',
				'PROJECT_NOT_FOUND': 'Projekt nenalezen',
				'ROLE_NOT_FOUND': 'Role nenalezena',
				'VARIABLE_EMPTY': 'Proměnná je prázdná',
				'VARIABLE_NOT_FOUND': 'Proměnná nenalezena',
			} satisfies Record<CreateApiKeyFormErrorCode, string>,
		},
		invite: {
			email: 'E-mail',
			name: 'Jméno',
			roles: 'Role',
			addRole: 'Přidat novou roli',
			submit: 'Pozvat',
			errorMessages: {
				'FIELD_REQUIRED': 'Toto pole je povinné',
				'UNKNOWN_ERROR': 'Něco se pokazilo. Zkuste to prosím později',
				'ALREADY_MEMBER': 'Tato osoba je již členem',
				'INVALID_EMAIL_FORMAT': 'Neplatný formát e-mailu',
				'INVALID_MEMBERSHIP': 'Neplatné členství',
				'PROJECT_NOT_FOUND': 'Projekt nenalezen',
				'ROLE_NOT_FOUND': 'Role nenalezena',
				'VARIABLE_EMPTY': 'Proměnná je prázdná',
				'VARIABLE_NOT_FOUND': 'Proměnná nenalezena',
			} satisfies Record<InviteFormErrorCode, string>,
		},
		changePassword: {
			currentPassword: 'Aktuální heslo',
			newPassword: 'Nové heslo',
			confirmPassword: 'Potvrdit heslo',
			submit: 'Změnit heslo',
			errorMessages: {
				'FIELD_REQUIRED': 'Toto pole je povinné',
				'INVALID_VALUE': 'Neplatná hodnota',
				'UNKNOWN_ERROR': 'Něco se pokazilo. Zkuste to prosím později',
				'PASSWORD_MISMATCH': 'Hesla se neshodují',
				'TOO_WEAK': 'Heslo je příliš slabé',
				'NO_PASSWORD_SET': 'Váš účet nemá nastavené heslo',
				'INVALID_PASSWORD': 'Neplatné heslo',
				'NOT_A_PERSON': 'Neplatný uživatel',
			} satisfies Record<ChangeMyPasswordFormErrorCode, string>,
		},
		login: {
			email: 'E-mail',
			password: 'Heslo',
			otpToken: '2FA OTP token',
			forgotPassword: 'Zapomněli jste heslo?',
			login: 'Přihlásit se',
			errorMessages: {
				'FIELD_REQUIRED': 'Toto pole je povinné',
				'INVALID_VALUE': 'Neplatná hodnota',
				'UNKNOWN_ERROR': 'Něco se pokazilo. Zkuste to prosím později',
				'INVALID_PASSWORD': 'Neplatné heslo',
				'INVALID_OTP_TOKEN': 'Neplatný OTP token',
				'NO_PASSWORD_SET': 'Heslo není nastaveno',
				'PERSON_DISABLED': 'Osoba je deaktivována',
				'UNKNOWN_EMAIL': 'Neznámý e-mail',
				'OTP_REQUIRED': undefined,
			} satisfies Record<LoginFormErrorCode, string | undefined>,
			idpInitError: 'Nepodařilo se inicializovat IdP přihlášení:',
			idpResponseError: 'Nepodařilo se zpracovat IdP odpověď:',
			title: 'Přihlášení',
			description: 'Zadejte svůj e-mail níže pro přihlášení do svého účtu',
			backToLogin: 'Zpět na přihlášení',
		},
		personList: {
			email: 'E-mail',
			name: 'Jméno',
			deleted: 'Člen smazán',
			deleteFailed: 'Nepodařilo se smazat člena',
			noEmail: 'Uživatel nemá e-mail',
			noName: 'Uživatel nemá jméno',
			role: 'Role',
			action: 'Akce',
			noResults: 'Žádní členové',
			deleteConfirmation: 'Opravdu chcete smazat tohoto člena?',
			deleteCancelAction: 'Zrušit',
			deleteConfirmAction: 'Smazat',
		},
		memberDelete: {
			cancel: 'Zrušit',
			confirm: 'Potvrdit',
		},
		otpSetup: {
			prepareContinue: 'Pokračovat',
			otpConfirmFormErrorMessages: {
				UNKNOWN_ERROR: 'Něco se pokazilo. Zkuste to prosím později',
				INVALID_OTP_TOKEN: 'Neplatný OTP token',
				FIELD_REQUIRED: 'Toto pole je povinné',
				NOT_PREPARED: 'Nastavení OTP nebylo inicializováno',
			} satisfies Record<OtpConfirmFormErrorCode, string>,
			otpPrepareFormErrorMessages: {
				UNKNOWN_ERROR: 'Něco se pokazilo. Zkuste to prosím později',
			} satisfies Record<OtpPrepareFormErrorCode, string>,
			alreadyHaveOtp: 'Již máte aktivní dvoufaktorové ověřování. Kliknutím na "Pokračovat" přestane staré fungovat.',
			nowScanQrCode: 'Nyní naskenujte následující QR kód ve své aplikaci:',
			writeDownCode: 'Po registraci do aplikace si zapište kód z aplikace.',
			twoFactorEnabled: 'Dvoufaktorové ověřování je AKTIVNÍ.',
			disableTwoFactor: 'Deaktivovat dvoufaktorové ověřování',
			disableTwoFactorConfirm: 'Opravdu chcete deaktivovat dvoufaktorové ověřování?',
			cancel: 'Zrušit',
			disable: 'Deaktivovat',
			disabledSuccess: 'Dvoufaktorové ověřování bylo deaktivováno.',
			setupAgain: 'Nastavit znovu',
			description: 'Dvoufázové ověřování poskytuje silnější zabezpečení vašeho účtu tím, že při přihlášení vyžaduje druhý krok ověření. Kromě hesla budete potřebovat také kód generovaný aplikací na vašem telefonu.',
			setupTwoFactor: 'Nastavit dvoufaktorové ověřování',
			enabledSuccess: 'Dvoufaktorové ověřování bylo aktivováno.',
			otpToken: 'OTP token',
			label: 'Štítek',
			confirm: 'Potvrdit',
		},

		passwordReset: {
			token: 'Resetovací kód',
			password: 'Heslo',
			passwordConfirmation: 'Potvrdit heslo',
			submit: 'Resetovat heslo',
			errorMessages: {
				'FIELD_REQUIRED': 'Toto pole je povinné',
				'INVALID_VALUE': 'Neplatná hodnota',
				'UNKNOWN_ERROR': 'Něco se pokazilo. Zkuste to prosím později',
				'PASSWORD_MISMATCH': 'Hesla se neshodují',
				'PASSWORD_TOO_WEAK': 'Heslo je příliš slabé',
				'TOKEN_EXPIRED': 'Token vypršel',
				'TOKEN_NOT_FOUND': 'Token nenalezen',
				'TOKEN_USED': 'Token již použit',
				'TOKEN_INVALID': 'Neplatný token',
			} satisfies Record<PasswordResetFormErrorCode, string>,
			title: 'Resetovat heslo',
			description: 'Zadejte nové heslo níže',
			success: 'Heslo bylo resetováno',
		},
		passwordResetRequest: {
			email: 'E-mail',
			submit: 'Požádat o resetovací kód',
			errorMessages: {
				'FIELD_REQUIRED': 'Toto pole je povinné',
				'INVALID_VALUE': 'Neplatná hodnota',
				'UNKNOWN_ERROR': 'Něco se pokazilo. Zkuste to prosím později',
				'PERSON_NOT_FOUND': 'Neznámý e-mail',
			} satisfies Record<PasswordResetRequestFormErrorCode, string>,
			title: 'Resetovat heslo',
			description: 'Zadejte svůj e-mail níže pro resetování hesla',
			success: 'Odkaz na resetování hesla byl odeslán',
			checkMail: 'Prosím zkontrolujte svou schránku pro instrukce, jak resetovat heslo.',
			entryCode: 'Nebo zadejte resetovací kód přímo.',
		},
		passwordlessSignInInit: {
			email: 'E-mail',
			submit: 'Odeslat magický odkaz',
			errorMessages: {
				'FIELD_REQUIRED': 'Toto pole je povinné',
				'INVALID_VALUE': 'Neplatná hodnota',
				'UNKNOWN_ERROR': 'Něco se pokazilo. Zkuste to prosím později',
				'PERSON_NOT_FOUND': 'Neznámý e-mail',
				'PASSWORDLESS_DISABLED': 'Přihlášení bez hesla není dostupné',
			} satisfies Record<PasswordlessSignInInitFormErrorCode, string>,
			title: 'Přihlásit se',
			description: 'Zadejte svůj e-mail níže pro přihlášení do svého účtu',
			success: 'Magický odkaz byl odeslán',
			checkMail: 'Prosím zkontrolujte svou schránku pro instrukce, jak se přihlásit.',
			entryCode: 'Nebo zadejte resetovací kód přímo.',
		},
		passwordlessSignIn: {
			token: 'Ověřovací kód',
			otpToken: '2FA OTP token',
			submit: 'Přihlásit se',
			errorMessages: {
				'FIELD_REQUIRED': 'Toto pole je povinné',
				'INVALID_VALUE': 'Neplatná hodnota',
				'UNKNOWN_ERROR': 'Něco se pokazilo. Zkuste to prosím později',
				'INVALID_OTP_TOKEN': 'Neplatný OTP token',
				'OTP_REQUIRED': undefined,
				'PERSON_DISABLED': 'Osoba je deaktivována',
				'TOKEN_EXPIRED': 'Token vypršel',
				'TOKEN_NOT_FOUND': 'Token nenalezen',
				'TOKEN_USED': 'Token již použit',
				'TOKEN_INVALID': 'Neplatný token',
			} satisfies Record<PasswordlessSignInFormErrorCode, string | undefined>,
			title: 'Přihlásit se',
		},
		otpActivation: {
			errorMessages: {
				'TOKEN_INVALID': 'Tento token není platný. Zkuste to prosím znovu.',
				'TOKEN_USED': 'Token již použit',
				'TOKEN_NOT_FOUND': 'Token nenalezen',
				'TOKEN_EXPIRED': 'Token vypršel',
				'UNKNOWN_ERROR': 'Něco se pokazilo. Zkuste to prosím později',
			} satisfies Record<ActivatePasswordlessOtpErrorCode | 'UNKNOWN_ERROR', string>,
		},

		updateProjectMember: {
			updateSuccess: 'Role aktualizovány',
			roles: 'Role',
			submit: 'Aktualizovat roli',
			errorMessages: {
				'FIELD_REQUIRED': 'Toto pole je povinné',
				'UNKNOWN_ERROR': 'Něco se pokazilo. Zkuste to prosím později',
				'INVALID_MEMBERSHIP': 'Neplatné členství',
				'PROJECT_NOT_FOUND': 'Projekt nenalezen',
				'ROLE_NOT_FOUND': 'Role nenalezena',
				'VARIABLE_EMPTY': 'Proměnná je prázdná',
				'VARIABLE_NOT_FOUND': 'Proměnná nenalezena',
				'NOT_MEMBER': 'Není členem',
			} satisfies Record<UpdateProjectMemberFormErrorCode, string>,
		},
	},
}
export const dictFormat = (value: string, replacements: Record<string, string>) => {
	return value.replace(/\${([^}]+)}/g, (_, key) => replacements[key] || '')
}
